import * as React from "react"
import Layout from "../components/layout"
import ProjectCard from "../components/project-card"
import ProjectPage from "../less/project-page.module.less"
import Container from "../components/container"
import {graphql} from "gatsby"
import useIntl from "../helpers/useIntl"
import CardGrid from "../components/card-grid"
import Jazz from "../images/jazz.png";

const Projects = ({data}) => {
  const intl = useIntl()
  const projects = data.allProject.edges

  return (
    <Layout heroBgColor={"var(--color-ternary)"}
            heroTitle={intl.formatMessage({id: "projects_hero_title"})}
            heroTextColor={"#142A7F"}
            heroSize={'small'}>
      <Container size={"small"}>
        <section>
          <h3>{intl.formatMessage({id: "projects_all"})}</h3>
          <CardGrid className={ProjectPage.projects}>
            {projects.map((project, i, key) =>
              <ProjectCard key={i}
                           title={intl.translate(project.node, "title")}
                           categories={project.node.fields.projectCategories}
                           description={intl.translate(project.node, "description")}
                           slug={project.node.slug}
                           coverImageLink={Jazz}
                           coverImageAlt={''}/>,
            )}
          </CardGrid>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allProject {
      edges {
        node {
          id
          slug
          title_fr
          title_nl
          description_nl
          description_fr
          fields {
            projectCategories {
              name
              title_fr
              title_nl
            }
          }
        }
      }
    }
  }
`


export default Projects
